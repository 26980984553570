.content .page-content{
padding: 30px;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    box-shadow: none;
    position: absolute !important;
    padding: 0;
    margin: 0;


    #sidebarCollapse{
        border: thin solid #ccc;
        border-radius: 50%;
        background: #ccc;
        font-size: 1rem;
        margin-left: -18px;
        z-index: 99;
    }
}

.dropdown{
    width: 100%;
    border-bottom: thin solid #9f9d9d;
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    min-height: calc(100vh - 85px);
    background-color: #164194;
    color: #fff;
    transition: all 0.3s;

    .dropdown-menu {
        position: relative !important;
        transform: translate3d(0px, 0, 0) !important;
        background: #254b64;
        box-shadow: none;
        border-radius: 0;

        .dropdown-item {
            padding: 0.3rem 1.5rem;
            color: #fff;

            &:hover {
                background-color: transparent;
            }

            a {
                background-color: transparent;
                color: #fff;
                text-decoration: none;
            }
        }
    }

    .show {
        &.dropdown {
            background-color: #45b34e;
            padding-top: 0;

            .dropdown-toggle {
                &::after {
                    border-top: 0;
                    border-right: 0.3em solid transparent;
                    border-bottom: 0.3em solid;
                    border-left: 0.3em solid transparent;
                }
            }
        }
    }
}

#sidebar.active {
    margin-left: -230px
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #005086
}

#sidebar ul.components {
    padding: 20px 0px;
    border-bottom: 1px solid #47748b
}

#sidebar ul p {
    padding: 10px;
    font-size: 15px;
    display: block;
    color: #fff
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: #fff;
    text-decoration: none;
}

#sidebar ul li a:hover {
    color: #fff;
    background: #318fb5
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #318fb5
}

a[data-toggle="collapse"] {
    position: relative
}
.dropdown-toggle{
    position: relative;
    
    &.btn-primary{
    background-color: transparent;
    border: transparent;
    box-shadow: none;
    }
    &.btn{
        padding: 20px 15px;
        &:hover{
            background-color: transparent;
            border: transparent;
            box-shadow: none;
        }
        &:active{
            background-color: transparent;
            border: transparent;
            box-shadow: none;
            &:focus{
                background-color: transparent;
                border: transparent;
                box-shadow: none;
            }
        }
        &:focus{
            background-color: transparent;
            border: transparent;
            box-shadow: none;
        }
        
    }

    &:focus{
        border: none;
        outline: none;
    }
    
}
.dropdown-toggle::after {
    display: block;
    position: absolute;
    right: -7px;
    transform: translateY(-50%);
    top: 34px;
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #318fb5
}

ul.CTAs {
    padding: 20px
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px
}

a.download,
a.download:hover {
    background: #318fb5;
    color: #fff
}

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s

}

.content{
    width: calc(100% - 20px); 
    &.collapsed{
        width: calc(100% - 260px);
    }
}

.content-wrapper {
    padding: 15px
}

@media(maz-width:768px) {
    #sidebar {
        margin-left: -250px
    }

    #sidebar.active {
        margin-left: 0px
    }

    #sidebarCollapse span {
        display: none
    }
}


#server-error-popup{
    z-index: 9999;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(126, 122, 122);
    display: flex;
    opacity: 0.9;

    .k-popup{
        display: flex;
        position: absolute;
        margin: 0px auto;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        padding: 25px;
        opacity: 0.7;
    }
}

.csvbtn{
    a{
        &:hover{
            color:#fff;
        }
    }
}