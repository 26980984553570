@import url("https: //cdn.syncfusion.com/ej2/20.3.47/bootstrap5.css");

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #151950;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.k-loader {
    .k-loader-canvas {
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 9999;
    }
}

.k-grid th.k-sorted {
    background-color: #e1dede !important;
}

.export-button {
    padding: 9px 35px !important;
    margin: 5px 10px !important;
}

.activeButton-table {
    background-color: #45b34e !important;
    border-color: #45b34e !important;
    box-shadow: none !important;
}

.e-cellvalue,
.e-rowcell {
    color: #212529 !important;
    font-weight: inherit !important;
    font-size: 16px !important;
}

.btn.show {
    background-color: #45b34e !important;
    box-shadow: none !important;
}

.e-grid .e-headercell {
    background-color: #e1dede;
}

.e-grid .e-rowcell.e-increase:not(.e-editedbatchcell):not(.e-updatedtd) {
    background-color: #80cbc4;
}

.e-grid .e-rowcell.e-decrease:not(.e-editedbatchcell):not(.e-updatedtd) {
    background-color: #f48fb1;
}

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
    opacity: 1;
}
