#top-exposures{
    .k-group-indicator{
        .k-link{
            font-size: 0.8rem;
            font-weight: 500;
        }
    } 

    .k-grid-table{
        border-spacing: 1px;
        width: none !important;
    }
    .k-grid{
        td{
            // font-weight: 700;
            // font-size: 0.8rem; 
            .cellName{
                // display: block;
                text-align: center;
                // font-size: 12px;
                color: #000;
                padding-bottom: 6px;
            }
            .result{
                // display: block;
                text-align: center;
                // border-top: thin solid #e2dede;
                padding-top: 3px;
                // font-size: 0.8rem;
                font-weight: 700;
            }
        }
    }
    .k-tabstrip-items-wrapper{
        border-color: #b2b5b8;
        .k-item.k-active {
            border-color: #b2b5b8;
        }
    } 

    .k-tabstrip-top {
        .k-tabstrip-items-wrapper{
            .k-item.k-active {
                border-bottom: transparent;
            }
        }
    }  
    .k-tabstrip{
        .k-content{
            border-color: #b2b5b8;
            padding: 1rem 0;
        }
    } 
}

.k-pivotgrid-row-headers table.k-pivotgrid-table {
    table-layout: auto;
}

.k-pivotgrid-column-headers col,
.k-pivotgrid-values col {
    width: 150px;
}

.k-grouping-row{
    td{
        tr{
            td{
                border-radius: 3px;
                font-weight: normal;
                // font-size: 0.9rem;
                &.green{
                    color:#80cbc4;
                }
                &.red{
                    color:#f48fb1;
                }

                &:first-child{
                    //min-width: 200px;
                    width: 260px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    border-top-width: 0;
                }
            }
        }
    }
}

#closepopup{
    z-index: 99999;
    color: #f32919;
    font-weight: 700;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: -12px;
    top: -15px;
    background: #fff;
    width: 32px;
    border-radius: 20px;
    height: 32px;
    text-align: center;
    border: 2px solid #ccc;
    line-height: 24px;
}

#calendarBtn{
    width: 100%;
}