.revenue-reports-wrapper{
    .k-grid{
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        th{
            border-color: #fff;
        }
    }

    .custom-box-shadow{
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }

    .section-heading{
        padding: 0.5rem 1rem;
        margin-bottom: 0;
        background: rgb(22,65,148);
        background: linear-gradient(90deg, rgba(22,65,148,1) 0%, rgba(76,76,187,1) 35%, rgba(0,212,255,1) 100%);
        color: #fff;
        h5{
            font-weight: 600;
            font-size: 1.2rem;
        }
    }
} 