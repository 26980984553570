.k-grid {
    table {
        width: 100% !important;
    }

    td {
        border-width : 0 0 1px 1px !important
    }

   
    
    thead {
        tr {
            th {
                white-space: unset;
                font-weight: 700;
                background-color: #e1dede;

                .k-cell-inner {
                    .k-link {
                        .k-column-title {
                            word-break: break-all;
                            white-space: break-spaces;
                            text-overflow: initial;
                            font-size: 0.9rem;
                            color: #343232;
                            font-weight: 600;
                            -webkit-font-smoothing: antialiased;
                        }
                    }
                }

                

                .totalAmount{
                    font-weight: 700;
                    font-size: 1rem;
                    display: flex;
                    background: #56c661;
                    border-radius: 5px;
                    height: 50px;
                    text-align: center;
                    vertical-align: middle;
                    border: thin solid #ccc;
                    color: #fff;
                    justify-content: center;
                    align-items: center;
                    &.green{
                        background-color:green;
                    }
                    &.red{
                        background-color:red;
                    }
                }
            }
        }
    }
}


.radio-form{
    .k-radio-list{
         display: inline-flex !important;
    }
    .timelabel{
        font-weight: 700;
        margin: 0 7px 0 0;
        line-height: 2rem;
    }
    .switchlabel{
        font-weight: 700;
        
        line-height: 2rem;
        &.book{
            margin: 0 7px 0 22px;
        }
        &.asset{
            margin: 0 7px 0 7px;
        }
    }
}

.trading-headlinges-block-structure{
    width: calc(100%);
    max-height: 450px;
    overflow: auto;
    border-radius: 5px;
    padding: 10px;
    border: thin solid #ccc;
    box-shadow: 8px 8px 2px 0px rgba(219,216,216,0.75);
    -webkit-box-shadow: 8px 8px 2px 0px rgba(219,216,216,0.75);
    -moz-box-shadow: 8px 8px 2px 0px rgba(219,216,216,0.75);
    .row{
        flex-wrap: nowrap;
    }
    .item-wrapper{
        width: 143px;
        padding: 0.10rem;
        .block-title{
            display: flex;
            padding: 5px;
            background-color: #164194;
            vertical-align: middle;
            color: #fff;
            font-size: 0.8rem;
            min-height: 66px;
            justify-content: center;
            align-items: center;
        }
        .amount{
            color:#000;
            border-radius: 4px;
            font-size: 1rem;
            border: thin solid #ccc;
            padding: 10px 5px;
            display: grid;
            text-align: center;
            align-content: center;
            align-items: center;
            font-weight: 600;
            width: 100%;
    
            &.green{
                background-color: #80cbc4;
                // color:#fff;
            }
            &.red{
                background-color: #f48fb1;
                // color:#fff;
            }
        }
    }

    .content-row{
        .item-wrapper{
            .amount{
                color:#000;
                border: thin solid #ccc;
                font-weight: 400;
                text-align: center;
                background-color: #e6e6e6;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }
           
        }
    }
}

#closepopup{
    z-index: 99999;
    color: #f32919;
    font-weight: 700;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: -12px;
    top: -15px;
    background: #fff;
    width: 32px;
    border-radius: 20px;
    height: 32px;
    text-align: center;
    border: 2px solid #ccc;
    line-height: 24px;
}

#calendarBtn{
    width: 100%;
}

table {
    .k-master-row {
        text-align: center;
    }

    .k-cell-inner {
        .k-link {
            display: inline-block !important;
            text-align: center;
        }
    }
}
