.container{
    padding: 30px 10px;
}

.account-head{
    display: flex;
    justify-content: space-between;
    // border: 1px solid black;
    // padding: 10px 5px
}

.heading{
    padding: 25px 25px;
}

.k-card-title{
    display: flex;
    justify-content: center;
}

.k-card-body{
    padding: 10px 10px !important;
}