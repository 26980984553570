
.navbar-logo{
    height: 72px;
}
//background-color: #164194; blue

header{
    background-color: #164194;
    .nav{
        li{
            margin: 0 25px;
            a{
                color:#fff;
                text-decoration: none;
            }
            i{
                margin: 0 4px;
            }
        }
    }
}
//{props.location.pathname !== "/login" ? <Header /> : ""}