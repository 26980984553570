.divider:after,
.divider:before {
content: "";
flex: 1;
height: 1px;
background: #ccc;
}
.h-custom {
height: 100%;
}
@media (max-width: 450px) {
.h-custom {
height: 100%;
}
}
.social-icons{
    button{
        border-radius: 50%;
        width: 2.3125rem;
        height: 2.3125rem;
    }
}

.login{
    overflow: hidden;
}

.small-logo{
    padding-bottom: 25px;
    img{
        height: 80px;
    }
}

.big-logo{
    img{
        max-width:50%;
    }
}

.login-heading{
    color:#164194;
}

.sidebar-lhs{
    background-color:#164194;
    height:100%;
}

.sidebar-txt{
    color: #fff;
}

.highlights-list{
    line-height: 2rem;
    li{
        text-align: start;
    }
}

.login-btn{
    background-color: #45b34e;
    width: 100%;
    padding-left: 2.5rem;
    padding-right: 2.5rem
}
.signup-btn{
    background-color: #45b34e;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
.sidebar-content-wrapper{
    height: 100%;
    display: flex;
    vertical-align: middle;
    align-items: center;
}
//background-color: #164194; blue
// background-color: #45b34e; green

.error-feedback{
    color:red;
    font-size: 0.8rem;
    font-weight: 400;
}