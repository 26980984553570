@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 60px;
    height: 60px;
    border: 5px solid #f3f3f3;
    /* Light grey */
    border-top: 5px solid #383636;
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}